import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FeatureCard from "../components/feature-card";
import "./home.css";
import "./new_css/home.css";
import "./new_css/nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faBroom,
  faSnowplow,
  faLeaf,
  faScrewdriverWrench,
  faSquare,
  faDove,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
function refreshPage() {
  window.location.reload();
}
const scrollToTop = () => {
  window.scrollTo(0, 0);
};
const Home = (props) => {
  const [name, setName] = useState("");
  const [email, setEMail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [betreff, setBetreff] = useState("");
  const [nachricht, setNachricht] = useState("");
  const [openNav, setOpenNav] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_qzp1ntj";
    const templateId = "template_mng4baf";
    const publicKey = "6w5EbC8CNbNiq5A4G";

    const templateParams = {
      Name: name,
      EMail: email,
      Telefon: telefon,
      Nachricht: nachricht,
      Betreff: betreff,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setEmail("");
        setTelefon("");
        setBetreff("");
        setNachricht("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleNavOpen = () => {
    setOpenNav(!openNav);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Dienstleistungen Südwest</title>
        <meta property="og:title" content="Dienstleistungen Südwest" />
      </Helmet>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="logoHeaderContainer">
            <div className="home-container01">
              <Link to="/" className="home-navlink">
                <img
                  alt="image"
                  src="/1585861357%20(2)-1500h.png"
                  className="home-image"
                />
              </Link>
            </div>
            <span className="home-text">Dienstleistungen Südwest</span>
          </div>

          <div className="home-container02">
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <a href="#Home" className="home-link">
                  Home
                </a>
                <a href="#Dienstleistungen" className="home-link1">
                  Dienstleistungen
                </a>
                <a href="#Referenzen" className="home-link2">
                  Referenzen
                </a>
                <a href="#Über uns" className="home-link3">
                  Über uns
                </a>
                <a href="#Kontakt" className="home-link4">
                  Kontakt
                </a>
              </nav>
              <div className="home-buttons">
                <a
                  href="https://jobs.dienstleistungen"
                  className="home-link5 button"
                >
                  <span className="home-text001">
                    <span>Stellenauschreibung</span>
                    <br></br>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={() => handleNavOpen()}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>

          {openNav ? (
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div className="home-nav">
                <div className="home-top">
                  <img
                    alt="image"
                    src="/1585861357%20(2)-1500h.png"
                    className="home-logo"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="home-close-menu"
                    onClick={() => handleNavOpen()}
                  >
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <a
                    href="#Home"
                    onClick={() => handleNavOpen()}
                    className="home-link"
                  >
                    Home
                  </a>
                  <a
                    href="#Dienstleistungen"
                    onClick={() => handleNavOpen()}
                    className="home-link1"
                  >
                    Dienstleistungen
                  </a>
                  <a
                    href="#Referenzen"
                    onClick={() => handleNavOpen()}
                    className="home-link2"
                  >
                    Referenzen
                  </a>
                  <a
                    href="#Über uns"
                    onClick={() => handleNavOpen()}
                    className="home-link3"
                  >
                    Über uns
                  </a>
                  <a
                    href="#Kontakt"
                    onClick={() => handleNavOpen()}
                    className="home-link4"
                  >
                    Kontakt
                  </a>
                </nav>
                <div className="home-buttons">
                  <a
                    href="https://jobs.dienstleistungen"
                    className="home-link5 button"
                  >
                    <span className="home-text001">
                      <span>Stellenauschreibung</span>
                      <br></br>
                    </span>
                  </a>
                </div>
              </div>
              <div className="nav-socials">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          ) : null}
        </header>
      </div>
      <div id="Home" className="home-home">
        <video
          src="/Sequenz02.mp4"
          poster={"/DJI_0144.jpg"}
          muted
          autoPlay={true}
          className="home-video"
        ></video>
      </div>
      <div className="home-container05">
        <a href="#Kontakt" className="home-hero-button1 buttonFilled">
          Kontaktieren Sie uns
        </a>
      </div>

      {/* home page start */}
      <div id="Dienstleistungen" className="home-dienstleistungen">
        <div className="home-features-container featuresContainer">
          <div className="home-dienstleistungen1">
            <div className="home-container06">
              <span className="overline">
                <span>Dienstleistungen</span>
                <br></br>
              </span>
              <h2
                className="home-features-heading heading2"
                style={{ textAlign: "center" }}
              >
                Unsere  Leistungen
              </h2>
              <span className="home-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container07">
              <Link onClick={scrollToTop} to="/gebaeudereinigung">
                <FeatureCard
                  FontAwesomeIcon={faBroom}
                  heading="Gebäudereinigung"
                  subHeading="Glanzvolle Sauberkeit: Professionelle Gebäudereinigung für Ihr makelloses Arbeits- und Wohnumfeld."
                  className="home-feature-card1"
                ></FeatureCard>
                <FontAwesomeIcon icon="fa-solid fa-broom" />
              </Link>
              <Link onClick={scrollToTop} to="/winterdienst">
                <FeatureCard
                  FontAwesomeIcon={faSnowplow}
                  heading="Winterdienst"
                  subHeading="Sicherheit im Winter: Professioneller Winterdienst für geräumte und sichere Wege."
                  className="home-feature-card3"
                ></FeatureCard>
              </Link>
              <Link onClick={scrollToTop} to="/hausmeisterdienste">
                <FeatureCard
                  FontAwesomeIcon={faScrewdriverWrench}
                  heading="Hausmeisterservice"
                  subHeading="Alles im Griff: Zuverlässiger Hausmeisterservice für ein gepflegtes und funktionierendes Zuhause."
                  className="home-feature-card4"
                ></FeatureCard>
              </Link>
              <Link onClick={scrollToTop} to="/pflege-von-gruenanlagen">
                <FeatureCard
                  FontAwesomeIcon={faLeaf}
                  heading="Pflege von Grünanlagen"
                  subHeading="Professionelle Grünanlagenpflege für eine blühende und gepflegte grüne Oase."
                  className="home-feature-card2"
                ></FeatureCard>
              </Link>
              <Link onClick={scrollToTop} to="/grabpflege">
                <FeatureCard
                  FontAwesomeIcon={faDove}
                  heading="Grabpflege"
                  subHeading="Ewige Würde bewahren: Professionelle Grabpflege für einen würdevollen Abschied und ein gepflegtes Gedenken."
                  className="home-feature-card41"
                ></FeatureCard>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="Referenzen" className="home-referenzen">
        <div className="pricingContainer">
          <div className="home-gallery">
            <h1 className="home-text040">Auswahl unserer Zufriedenen Kunden</h1>
            <span className="home-text041">
              Zufriedene Kunden sprechen für sich!
            </span>
            <div className="home-container08">
              <img
                alt="image"
                src="/kik-logo.svg-200h.png"
                className="home-image01"
              />
              <img
                alt="image"
                src="/logo_kit.svg-200h.png"
                className="home-image02"
              />
              <img
                alt="image"
                src="/stadtrastatt_lg_4c-200h.jpg"
                className="home-image03"
              />
              <img
                alt="image"
                src="/tedi-logo.svg-200h.png"
                className="home-image04"
              />
              <img
                alt="image"
                src="/artikel-297-2018-05-16-14-22-09-pennymarktlogo-200h.jpeg"
                className="home-image05"
              />
              <img
                alt="image"
                src="/1200px-grammer_ag_logo.svg-200h.png"
                className="home-image06"
              />
              <img
                alt="image"
                src="/download-200h.png"
                className="home-image07"
              />
              <img
                alt="image"
                src="/tedox_logo.svg-200h.png"
                className="home-image08"
              />
            </div>
          </div>
          <div className="home-container27">
            <div className="home-container28">
              <div className="home-container29">
                <img
                  alt="image"
                  src="/seifert_logistik-200h.png"
                  className="home-image09"
                />
              </div>
            </div>
            <img
              src="/logo_kursana_4c.svg"
              alt="image"
              className="home-image20"
            />
          </div>
        </div>
      </div>
      <div id="Über uns" className="home-ueber-uns">
        <div className="home-container110">
          <div className="home-container111">
            <img src="/DJI_0144.jpg" alt="image" className="home-image10" />
            <div className="home-container112">
              <span className="home-text128">Unsere Philosophie</span>
              <span className="home-text129">
                <span>
                  Bei uns stehen Teamgeist, Respekt, Verantwortungsbewusstsein
                  und Integrität an höchster Stelle. Eine gute und offene
                  Zusammenarbeit ist der Schlüssel zum Erfolg!
                </span>
                <br></br>
                <br></br>
              </span>
            </div>
            <img
              src="/1585861357 (2)-1500h.png"
              alt="image"
              className="home-image11"
            />
          </div>
          <div className="home-container113">
            <div className="home-container114">
              <span className="home-text133">Unsere Dienstleistungen</span>
            </div>
            <div className="home-container115">
              <div className="home-container116">
                <span className="home-text134">Was?</span>
                <span className="home-text135">
                  Wir bieten Reinigungen, Grünanlagen-Pflege, Winterdienst,
                  Hausmeisterdienste und Küchenhilfen an.
                </span>
              </div>
              <div className="home-container117">
                <span className="home-text136">Wo?</span>
                <span className="home-text137">Unser Einzugsgebiet ist:</span>
                <ul className="home-ul list">
                  <li className="list-item">
                    <span>Rastatt</span>
                  </li>
                  <li className="list-item">
                    <span>Baden-Baden</span>
                  </li>
                  <li className="list-item">
                    <span>Bühl</span>
                  </li>
                  <li className="list-item">
                    <span>Gaggenau</span>
                  </li>
                  <li className="list-item">
                    <span>Karlsruhe</span>
                  </li>
                  <li className="list-item">
                    <span>Auf Anfrage gerne auch überregional</span>
                  </li>
                </ul>
              </div>
              <div className="home-container118">
                <span className="home-text144">Für wen?</span>
                <span className="home-text145">
                  Unsere Dienstleistungen bieten wir sowohl für
                  Gewerbetreibende, als auch für öffentliche Einrichtungen und
                  Privatpersonen an.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Kontakt" className="home-kontakt">
        <div className="home-container18">
          <div className="home-container19">
            <div className="home-container20">
              <span className="home-text073">Kontaktieren Sie uns</span>
              <span className="home-text074">
                Gerne Beantworten wir alle Ihre Fragen!
              </span>
              <span className="home-text075">
                <span>E-Mail</span>
                <br></br>
              </span>
              <a
                href="mailto:kontakt@dienstleistungen-suedwest.de?subject="
                className="home-link6"
              >
                <span>kontakt@dienstleistungen-suedwest.de</span>
                <br></br>
              </a>
              <span className="home-text080">Telefon</span>
              <span className="home-text081">
                <span>07222 15 88 361</span>
                <br></br>
              </span>
              <span className="home-text084">Adresse</span>
              <span className="home-text085">
                <span>Im Wöhr 3, 76437 Rastatt</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-container21">
            <div className="home-container22">
              <div className="home-container23">
                <iframe
                  className="MapsFrame"
                  src="https://www.google.com/maps/embed?pb=!3m2!1sde!2sde!4v1709050319338!5m2!1sde!2sde!6m8!1m7!1sLpamE6P9mxEtDlxuN6ODrQ!2m2!1d48.86273000907047!2d8.23269424086712!3f287.0663424555714!4f8.898000763822168!5f0.7820865974627469"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container24">
          <form onSubmit={handleSubmit} className="home-form">
            <span className="home-text088">Stellen Sie eine Anfrage</span>
            <span className="home-text089">Name</span>
            <input
              type="text"
              required="true"
              placeholder="Ihr  Name"
              className="home-textinput input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <span className="home-text090">E-Mail</span>
            <input
              type="text"
              required="true"
              placeholder="Ihre E-Mail-Adresse"
              className="home-textinput1 input"
              value={email}
              onChange={(e) => setEMail(e.target.value)}
            />
            <span className="home-text091">Telefon</span>
            <input
              type="text"
              enctype="Ihre Telefonnummer"
              placeholder="Ihre Telefonnummer"
              className="home-textinput2 input"
              value={telefon}
              onChange={(e) => setTelefon(e.target.value)}
            />
            <span className="home-text092">
              <span>Betreff</span>
              <br></br>
            </span>
            <input
              type="text"
              required="true"
              placeholder="Betreff Ihres Anliegens"
              className="home-textinput3 input"
              value={betreff}
              onChange={(e) => setBetreff(e.target.value)}
            />
            <span className="home-text095">Nachricht</span>
            <input
              type="text"
              required="true"
              placeholder="Ihre Nachricht an uns"
              className="home-textinput4 input"
              value={nachricht}
              onChange={(e) => setNachricht(e.target.value)}
            />
            <div className="home-container123">
              <span className="home-text169">Es gilt die </span>
              <Link to="/datenschutz" className="home-navlink16">
                Datenschutzerklärung.
              </Link>
            </div>
            <button
              type="submit"
              className="home-button button"
              onClick={refreshPage}
            >
              <Link to="#Kontakt" refresh="true">
                <span className="home-text096">
                  <span>Anfrage abschicken</span>
                  <br></br>
                </span>
              </Link>
            </button>
          </form>
        </div>
      </div>
      <footer className="home-footer">
        <img
          alt="logo"
          src="/1585861357%20(2)-1500h.png"
          className="home-image12"
        />
        <Link to="/impressum" className="home-navlink6">
          <span>Impressum</span>
          <br></br>
        </Link>
        <Link to="/datenschutz" className="home-navlink6">
          <span>Datenschutz</span>
          <br></br>
        </Link>
        <div className="home-icon-group1">
          <a
            href="https://www.instagram.com/dienstleistungen_suedwest/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link7"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Home;
