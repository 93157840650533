import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import './style.css'
import PflegeVonGrnanlagen from './views/pflege-von-grnanlagen'
import Gebudereinigung from './views/gebudereinigung'
import Home from './views/home'
import Grabpflege from './views/grabpflege'
import Winterdienst from './views/winterdienst'
import Hausmeisterdienste from './views/hausmeisterdienste'
import Impressum from './views/impressum'
import Datenschutz from './views/datenschutz'
import NotFound from './views/not-found'
import emailjs from '@emailjs/browser';
import CookieConsent from "react-cookie-consent";
import { Helmet } from 'react-helmet';
const App = () => {
  return (
    <Router>
      <CookieConsent
  location="bottom"
  buttonText="Annehmen"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
  
>
Wir nutzen Cookies um Ihren Aufenthalt auf unserer Seite zu verbessern. Sie akzeptieren unsere Cookies wenn Sie weiterhin auf unserer Webseite bleiben.{" "}
<a href="/datenschutz" className="index-link1">
                  Datenschutzerklärung
                </a>
                <link rel="icon" href="bild_32x32.png" />
</CookieConsent>
<Helmet>
<html lang="de" />
            <title>Dienstleistung Südwest</title>
            <meta name="Dienstleistung Südwest" content="Dienstleistung Südwest - Ihr verlässlicher Partner für Gebäudereinigung, Grünanlagenpflege, Grabpflege, Hausmeisterdienste und Winterdienst." />
        </Helmet>
      <Switch>
        <Route
          component={PflegeVonGrnanlagen}
          exact
          path="/pflege-von-gruenanlagen"
        />
        <Route component={Gebudereinigung} exact path="/gebaeudereinigung" />
        <Route component={Home} exact path="/" />
        <Route component={Grabpflege} exact path="/grabpflege" />
        <Route component={Winterdienst} exact path="/winterdienst" />
        <Route component={Hausmeisterdienste} exact path="/hausmeisterdienste" />
        <Route component={Impressum} exact path="/impressum" />
        <Route component={Datenschutz} exact path="/datenschutz" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
